import GATSBY_COMPILED_MDX from "/Users/touffi/dev/portfolio/data/pages/snippet/404.mdx";
import './404.scss';
import React from 'react';
import PropTypes from 'prop-types';
import {graphql} from 'gatsby';
import linkFactory from '../../core/linkFactory';
import HtmlHeaders from '../../ui/HtmlHeaders';
import Logo from '../../ui/layout/main/components/Logo';
const PageNotFound = ({data, pageContext, children}) => {
  const {mdx, site} = data;
  const {frontmatter} = mdx;
  return React.createElement("div", {
    className: 'mly-MainLayout_Container'
  }, React.createElement("header", {
    className: 'mly-Header'
  }, React.createElement(Logo, {
    siteTitle: site.siteMetadata.siteUrl,
    lang: pageContext.lang
  })), React.createElement("div", {
    className: 'mly-Main notFound-Container'
  }, React.createElement(HtmlHeaders, {
    title: frontmatter.title,
    metaDescription: frontmatter.metaDescription,
    path: linkFactory.getPageLink(frontmatter.slug, pageContext.lang),
    lang: pageContext.lang
  }), React.createElement("article", null, React.createElement("h1", null, frontmatter.title), React.cloneElement(children, {
    homeHref: linkFactory.getHomeLink(pageContext.lang)
  }))));
};
PageNotFound.propTypes = {
  data: PropTypes.object,
  pageContext: PropTypes.object,
  children: PropTypes.element
};
PageNotFound
export default function GatsbyMDXWrapper(props) {
  return React.createElement(PageNotFound, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const pageQuery = graphql`
	query ($pageSlug: String) {
		site {
			siteMetadata {
				title
			}
		}
		mdx(frontmatter: { slug: { eq: $pageSlug } }) {
			frontmatter {
				slug
				title
				metaDescription
			}
		}
	}
`;
